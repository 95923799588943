(function ($) {
    $(document).ready(function () {
        var mySwiper = new Swiper('.swiper-gallery-container', {
            // Navigation arrows
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            slidesPerView: 4,
            spaceBetween: 30,

            breakpoints: {

                1024: {
                    slidesPerView: 4,
                    spaceBetween: 30
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                425: {
                    slidesPerView: 1
                }
            }
        });
        var topswiper = new Swiper('.product-slider', {
            slidesPerView: 4,
            slidesPerGroup: 4,
            paginationClickable: true,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            spaceBetween: 14,
            breakpoints: {
                // when window width is <= 320px
                320: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    spaceBetweenSlides: 10
                },
                // when window width is <= 480px
                480: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetweenSlides: 15
                },
                // when window width is <= 640px
                768: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    spaceBetweenSlides: 15
                }
            }
        });
        $('img').unveil();
    });
    $(window).resize(function () {
        $('img').unveil();
    });
}(jQuery));


jQuery(function ($) {
    $('.js-archive__readMore').click(function () {
       $(this).next().slideToggle(300);
       $(this).hide();
    });
});
